'use client'

import { usePathname } from 'next/navigation'

import {
  errorRoute,
  homeRoute,
  kpisRoute,
  labResultsRoute,
  loginRoute,
  privacyPolicyRoute,
  profileRoute,
  recommendationsRoute,
  shopOrdersPageDynamicSignature,
  shopOrdersRoute,
  termsOfUseConsentRoute,
  termsOfUseRoute,
} from '../../../common/constants/routes'
import { useAccount } from '../../auth/hooks/useAccount'
import { useRoutePattern } from '../../../utilities/useRoutePattern'
import { Role } from '../../../common/constants/roles'

export const unauthenticatedEnabledPages = [
  loginRoute,
  homeRoute,
  errorRoute,
  termsOfUseRoute,
  termsOfUseConsentRoute,
  privacyPolicyRoute,
]

export const authenticatedEnabledPages = [
  labResultsRoute,
  kpisRoute,
  profileRoute,
  homeRoute,
  privacyPolicyRoute,
  termsOfUseRoute,
  shopOrdersRoute,
  recommendationsRoute,
]

export const authenticatedEnabledDynamicSignatures = [shopOrdersPageDynamicSignature]

export type UseReconstructionHandlerReturn = ReturnType<typeof useReconstructionHandler>
export function useReconstructionHandler() {
  const { isLoading, isAuthenticated, user } = useAccount()

  const isAdmin = [Role.admin, Role.realizeDeveloper, Role.orderManagement, Role.realizeEmployee].some(user?.hasRole)

  const pathname = usePathname()
  const routePattern = useRoutePattern()
  const showLoadingUi = isLoading
  const showUnderConstructionUi = !isAuthenticated && !isLoading
  const showLimitedUi = isAuthenticated
  const redirectToHome =
    pathname &&
    !isLoading &&
    (isAuthenticated
      ? !isAdmin &&
        !authenticatedEnabledPages.includes(pathname) &&
        !authenticatedEnabledDynamicSignatures.some((dynamicSignature) => dynamicSignature === routePattern)
      : !unauthenticatedEnabledPages.includes(pathname))

  return { showLoadingUi, showUnderConstructionUi, showLimitedUi, redirectToHome }
}
